import React from "react"
import { heroResolveRichText } from "../../utils/resolve-rich-text/heroResolveRichText"
import { Button } from "@atoms"
import OptimizedImage from "../../utils/optimizedImage"
import { LottieWrapper } from "../Lottie/LottieWrapper"
import {
  HeroUsecaseContainer,
  Kicker,
  Heading,
  Subhead,
  CategoriesWrapper,
  Category,
  BreadcrumbContainer,
} from "./styles/HeroUsecase.styled"
import Breadcrumbs from "./components/Breadcrumbs"

const HeroUsecase = ({ component, location, seoBreadcrumbs }) => {
  const {
    heading,
    headingSize,
    subheading,
    kicker,
    alignImage,
    callsToAction,
    reference,
    background,
    featuredImage,
    mobileBackgroundImage,
    breadcrumbs,
    breadcrumbTitle,
    categories,
    mobileTextAlign,
  } = component
  let lottie = reference?.primaryImage?.file?.contentType?.includes("json")
  let backgroundColor

  if (component?.background === "dark") {
    backgroundColor = "#101828"
  } else {
    if (component?.background === "light") {
      backgroundColor = "#f2f4f7"
    } else {
      backgroundColor = "white"
    }
  }

  return (
    <HeroUsecaseContainer
      alignImage={alignImage}
      reference={reference}
      className="flex"
      background={backgroundColor}
      mobileTextAlign={mobileTextAlign}
    >
      {featuredImage && (
        <>
          <OptimizedImage
            className="background-image-desktop"
            image={featuredImage.gatsbyImageData}
            mobileImage={mobileBackgroundImage.gatsbyImageData}
            src={featuredImage?.file?.url}
            alt=""
            loading="eager"
          />
          <OptimizedImage
            className="background-image-mobile"
            image={mobileBackgroundImage.gatsbyImageData}
            src={featuredImage?.file?.url}
            alt=""
            loading="eager"
          />
        </>
      )}
      {breadcrumbs && (
        <BreadcrumbContainer>
          <Breadcrumbs background={background} location={location} seoBreadcrumbs={seoBreadcrumbs} breadcrumbTitle={breadcrumbTitle}/>
        </BreadcrumbContainer>
      )}
      <div className="container">
        <div className="text-content-side">
          <div className="text-content-wrapper">
            {categories && (
              <CategoriesWrapper>
                {categories?.map((category, index) => (
                  <Category
                    key={index}
                    style={{ background: category?.backgroundColor }}
                  >
                    {category?.title}
                  </Category>
                ))}
              </CategoriesWrapper>
            )}
            {kicker && <Kicker theme={component?.background}>{kicker}</Kicker>}
            {heading && (
              <Heading headingSize={headingSize} theme={component?.background}>
                {heading}
              </Heading>
            )}
            {subheading && (
              <Subhead theme={component?.background}>
                {heroResolveRichText(component, background)}
              </Subhead>
            )}
            <div className="btn-wrapper">
              {callsToAction &&
                callsToAction[0]?.link &&
                callsToAction.map(btn => (
                  <Button
                    ctaVariant={btn?.variant || "primary"}
                    ctaLink={btn?.link}
                  >
                    {btn?.label}
                  </Button>
                ))}
            </div>
          </div>
        </div>
        <div className="img-side">
          {reference?.__typename === "ContentfulComponentImage" && !lottie && (
            <OptimizedImage
              loading="eager"
              image={reference?.primaryImage?.gatsbyImageData}
              src={reference?.primaryImage?.file.url}
            />
          )}
          {reference?.__typename === "ContentfulComponentImage" && lottie && (
            <LottieWrapper
              imageAnimation={reference?.primaryImage?.file?.url}
            />
          )}
        </div>
      </div>
    </HeroUsecaseContainer>
  )
}

export default HeroUsecase
